import { ThemeContext } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { makeTokenIndex } from '../util';
import { palette } from './palette'; // https://material-ui.com/customization/default-theme/
var headerFSBase = '2.4rem';
var makeTheme = function makeTheme() {
    var themeDeclaration = { palette: palette, typography: { fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif', h1: { fontSize: "calc(" + headerFSBase + " * 1)", lineHeight: '48px', fontWeight: 700, gutter: { marginTop: '3rem', marginBottom: '1.5rem' } }, h2: { fontSize: "calc(" + headerFSBase + " * 0.9)", fontWeight: 600, gutter: { marginTop: '1.5rem', marginBottom: '1.5rem' } }, h3: { fontSize: "calc(" + headerFSBase + " * 0.6)", fontWeight: 600, gutter: { marginTop: '1rem', marginBottom: '1rem' } }, h4: { fontSize: "calc(" + headerFSBase + " * 0.5)", fontWeight: 600, gutter: { marginTop: '1rem', marginBottom: '1rem' } }, h5: { fontSize: "calc(" + headerFSBase + " * 0.5)", fontWeight: 600, gutter: { marginTop: '1rem', marginBottom: '1rem' } }, h6: { fontSize: "calc(" + headerFSBase + " * 0.5)", fontWeight: 600, gutter: { marginTop: '1rem', marginBottom: '1rem' } }, body1: { fontSize: '1rem', gutter: { marginTop: '1.2rem', marginBottom: '1.2rem' } }, body: { fontSize: '1rem' }, small: { fontSize: '0.8rem' }, micro: { fontSize: '0.6rem' }, caption: { fontFamily: 'Titillium Web, sans-serif', fontSize: "calc(" + headerFSBase + " * 2)", fontWeight: 700 }, captionSmall: { fontSize: "calc(" + headerFSBase + " * 1.05)" } }, shape: { borderRadius: 5 }, spacing: function spacing(value) { return value * 4; } };
    var muiTheme = createTheme(themeDeclaration); // @ts-ignore
    return Object.assign({}, muiTheme, {
        tokenIndex: makeTokenIndex(muiTheme)
    });
};
export var theme = makeTheme();
export var useTheme = function useTheme() { return useContext(ThemeContext); };
