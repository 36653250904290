import _extends from "@babel/runtime/helpers/esm/extends";
import { Typography, Link, HR } from '../default'; // const wrap = (children: ReactNode) => <Container>{children}</Container>;
import { jsx as ___EmotionJSX } from "@emotion/react";
var Paragraph = function Paragraph(props) { return ___EmotionJSX(Typography, _extends({}, props, { variant: "body1", component: "p", enableVerticalGutter: true })); };
var H1 = function H1(props) { return ___EmotionJSX(Typography, _extends({}, props, { variant: "h1", component: "h1", enableVerticalGutter: true })); };
var H2 = function H2(props) { return ___EmotionJSX(Typography, _extends({}, props, { variant: "h2", component: "h2", enableVerticalGutter: true })); };
var H3 = function H3(props) { return ___EmotionJSX(Typography, _extends({}, props, { variant: "h3", component: "h3", enableVerticalGutter: true })); };
var H4 = function H4(props) { return ___EmotionJSX(Typography, _extends({}, props, { variant: "h4", component: "h4", enableVerticalGutter: true })); };
var PageSeparator = function PageSeparator() { return ___EmotionJSX(HR, null); };
export var MDXComponents = { a: Link, p: Paragraph, h1: H1, h2: H2, h3: H3, h4: H4, hr: PageSeparator // pre: Listing,
    //inlineCode: InlineCode,
};
