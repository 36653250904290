module.exports.site = {
    title: 'Blender Hotkeys',
    logoText: 'Blender Hotkeys',
    description:
        'Blender Hotkeys',
    keywords: [
        'one',
        'two',
        'three',
    ],
    author: '@gannochenko',
    baseURL: 'https://blenderhotkeys.gannochenko.dev',
};