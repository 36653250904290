import { animationGroup } from './animation';
import { brushGroup } from './brush';
import { cameraGroup } from './camera';
import { collectionsGroup } from './collections';
import { compositingGroup } from './compositing';
import { cursorGroup } from './cursor';
import { curvesGroup } from './curves';
import { etcGroup } from './etc';
import { geometryGroup } from './geometry';
import { grsGroup } from './grs';
import { manipulationGroup } from './manipulation';
import { navigationGroup } from './navigation';
import { originGroup } from './origin';
import { proportionalEditModeGroup } from './proportionalEditMode';
import { renderingGroup } from './rendering';
import { sculptingGroup } from './sculpting';
import { selectionGroup } from './selection';
import { shadingGroup } from './shading';
import { texturePaintGroup } from './texturePaint';
import { uvGroup } from './uv';
import { viewGroup } from './view';
import { nodesGroup } from './nodes';
export var groups = [viewGroup, selectionGroup, manipulationGroup, geometryGroup, originGroup, curvesGroup, etcGroup, grsGroup, navigationGroup, cursorGroup, proportionalEditModeGroup, uvGroup, collectionsGroup, brushGroup, sculptingGroup, texturePaintGroup, nodesGroup, shadingGroup, cameraGroup, compositingGroup, animationGroup, renderingGroup];
