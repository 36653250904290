import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
var _excluded = ["children"];
import { useMemo } from 'react';
export var useTypography = function useTypography(_ref) { var children = _ref.children, restProps = _objectWithoutPropertiesLoose(_ref, _excluded); var trueChildren = useMemo(function () { if (typeof children !== 'string') {
    return children;
} return children.replace('{a}', '').trim(); }, [children]); var aKey = useMemo(function () { if (!children || typeof children !== 'string') {
    return '';
} if (children.toString().indexOf('{a}') < 0) {
    return '';
} return children.toString().replace('{a}', '').trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9_-]/g, ''); }, [children]); return { restProps: restProps, trueChildren: trueChildren, aKey: aKey }; };
