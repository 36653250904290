import { useCallback, useState } from 'react';
import { CookiePopupAgreeButton, CookiePopupAgreeButtonXS, CookiePopupPicture, CookiePopupRoot, CookiePopupText } from './style';
import { Link } from '../Link';
import { getWindow } from '../../../util/getWindow';
import { COOKIE_POLICY } from '../../../pathTemplates';
import { jsx as ___EmotionJSX } from "@emotion/react";
var win = getWindow();
export var CookiePopup = function CookiePopup() { var _useState = useState(win ? !win.localStorage.getItem('cookie-accept') : false), displayed = _useState[0], setDisplayed = _useState[1]; var _useState2 = useState(false), fadingAway = _useState2[0], setFadingAway = _useState2[1]; var onAcceptClick = useCallback(function () { if (typeof window === 'undefined') {
    return;
} window.localStorage.setItem('cookie-accept', '1'); setFadingAway(true); setTimeout(function () { return setDisplayed(false); }, 600); }, []); if (!displayed) {
    return null;
} return ___EmotionJSX(CookiePopupRoot, { fadingAway: fadingAway }, ___EmotionJSX(CookiePopupPicture, null), ___EmotionJSX(CookiePopupText, null, "I use ", ___EmotionJSX("b", null, "cookies"), " to improve your experience with my website.", ___EmotionJSX("br", null), "By further browsing you agree", ___EmotionJSX("br", null), " to accept the cookies. ", ___EmotionJSX("br", null), "More information ", ___EmotionJSX(Link, { to: COOKIE_POLICY }, "here"), ".", ___EmotionJSX(CookiePopupAgreeButton, { onClick: onAcceptClick, variant: "contained", color: "primary", size: "small" }, "Understood!"), ___EmotionJSX("div", null, ___EmotionJSX(CookiePopupAgreeButtonXS, { onClick: onAcceptClick, variant: "contained", color: "primary", size: "small" }, "Understood!")))); };
