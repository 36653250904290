import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
var _excluded = ["query", "onQueryChange"];
import { useEffect, useRef } from 'react';
var findInput = function findInput(ref) { var _ref$current; return (_ref$current = ref.current) === null || _ref$current === void 0 ? void 0 : _ref$current.querySelector('input'); };
export var useHotkeySearch = function useHotkeySearch(_ref) { var query = _ref.query, onQueryChange = _ref.onQueryChange, props = _objectWithoutPropertiesLoose(_ref, _excluded); var searchInputRef = useRef(); var focusTimeout = useRef(); useEffect(function () { focusTimeout.current = setTimeout(function () { var input = findInput(searchInputRef); if (input) {
    input.focus();
} }, 300); return function () { clearTimeout(focusTimeout.current); }; }, []); useEffect(function () { var handleKeyPress = function handleKeyPress(event) { if (event.key === 'Escape') {
    onQueryChange === null || onQueryChange === void 0 ? void 0 : onQueryChange('');
    var input = findInput(searchInputRef);
    if (input) {
        input.focus();
    }
} }; document.addEventListener('keydown', handleKeyPress); return function () { document.removeEventListener('keydown', handleKeyPress); }; }, []); var onSearchInputChange = function onSearchInputChange(event) { onQueryChange === null || onQueryChange === void 0 ? void 0 : onQueryChange(event.target.value); }; return { rootProps: Object.assign({}, props), searchInputProps: { ref: searchInputRef, value: query, onChange: onSearchInputChange }, showSearchButton: query !== '', clearButtonProps: { onClick: function onClick() { onQueryChange === null || onQueryChange === void 0 ? void 0 : onQueryChange(''); var input = findInput(searchInputRef); if (input) {
            input.focus();
        } } } }; };
