import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { theme, GlobalStyle } from '../../style';
import { MDXComponents } from './MDXComponents';
import { StateProviders } from '../../states/providers';
import { NetworkStatusProvider } from '../default';
import { i18n } from '../../i18n/i18n';
import { jsx as ___EmotionJSX } from "@emotion/react";
var queryClient = new QueryClient(); /**
 * This is a top-level wrapper, it wraps everything else, including the ApplicationLayout.
 */
export var Providers = function Providers(_ref) { var children = _ref.children; return ___EmotionJSX(SimpleReactLightbox, null, ___EmotionJSX(ThemeProvider, { theme: theme }, ___EmotionJSX(React.Fragment, null, ___EmotionJSX(GlobalStyle, null), ___EmotionJSX(QueryClientProvider, { client: queryClient }, ___EmotionJSX(MDXProvider, { components: MDXComponents }, ___EmotionJSX(SnackbarProvider, { maxSnack: 3 }, ___EmotionJSX(NetworkStatusProvider, null, ___EmotionJSX(I18nextProvider, { i18n: i18n }, ___EmotionJSX(StateProviders, null, children))))))))); };
