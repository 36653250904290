// https://material.io/resources/color/#!/?view.left=0&view.right=0
// https://mui.com/customization/default-theme/
module.exports.palette = {
    primary: {
        main: '#ffd54f',
        light: '#ffff81',
        dark: '#c8a415',
        contrastText: '#000',
    },
    secondary: {
        main: '#9e9e9e',
        light: '#cfcfcf',
        dark: '#707070',
        contrastText: '#000',
    },
    text: {
        primary: '#f6f6f6', // todo: rename to main
        inverted: '#333',
    },
    background: {
        default: '#fafafa', // todo: rename to main
        dark: '#20232a', // todo: rename to inverted
        gradientStep1: '#80060c',
        gradientStep2: '#590979',
        gradientStep3: '#000',
    },
};
