import { useEffect, useState } from 'react';
import { getWindow } from '../../../../util/getWindow';
var win = getWindow();
export var useNetworkStatusProvider = function useNetworkStatusProvider() {
    var _useState = useState({ online: true, setOnline: function setOnline(isOnline) { return setState(Object.assign({}, status, { online: isOnline })); } }), status = _useState[0], setState = _useState[1];
    useEffect(function () { if (!win) {
        return;
    } var onOnline = function onOnline() { status.setOnline(true); }; var onOffline = function onOffline() { status.setOnline(false); }; win.addEventListener('online', onOnline); win.addEventListener('offline', onOffline); return function () { win.removeEventListener('online', onOnline); win.removeEventListener('offline', onOffline); }; }, [status]);
    return status;
};
