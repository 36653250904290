import Helmet from 'react-helmet';
import { Query } from './query';
import { jsx as ___EmotionJSX } from "@emotion/react";
export var SEO = function SEO(_ref) {
    var _ref$description = _ref.description, description = _ref$description === void 0 ? '' : _ref$description, _ref$lang = _ref.lang, lang = _ref$lang === void 0 ? 'en' : _ref$lang, _ref$meta = _ref.meta, meta = _ref$meta === void 0 ? [] : _ref$meta, _ref$keywords = _ref.keywords, keywords = _ref$keywords === void 0 ? [] : _ref$keywords, _ref$title = _ref.title, title = _ref$title === void 0 ? '' : _ref$title, _ref$image = _ref.image, image = _ref$image === void 0 ? '' : _ref$image;
    return ___EmotionJSX(Query, null, function (data) {
        var metaTitle = '';
        var metaTitleOG = '';
        var metaDescription = description || data.site.siteMetadata.description;
        if (title) {
            metaTitle = title + " | " + data.site.siteMetadata.title;
            metaTitleOG = title;
        }
        else {
            metaTitle = data.site.siteMetadata.title;
            metaTitleOG = data.site.siteMetadata.description;
        }
        var allKeywords = [];
        if (typeof keywords === 'string') {
            allKeywords = allKeywords.concat(keywords.split(',').map(function (word) { return word.trim(); }));
        }
        allKeywords = allKeywords.concat(data.site.siteMetadata.keywords).filter(function (x) { return !!x; });
        return ___EmotionJSX(Helmet, { htmlAttributes: { lang: lang }, title: metaTitle // titleTemplate={
            //     title
            //         ? `%s | ${data.site.siteMetadata.title}`
            //         : metaTitle
            // }
            ,
            meta: [{ name: 'twitter:card', content: 'summary' }, { name: 'twitter:creator', content: '@gannochenko' }, { name: 'description', content: metaDescription }, { property: 'og:title', content: metaTitleOG }, { property: 'og:description', content: metaDescription }, { property: 'og:type', content: 'website' }, image ? { property: 'og:image', content: image } : {}].concat(allKeywords.length > 0 ? [{ name: "keywords", content: allKeywords.join(", ") }] : []).concat(meta).filter(function (x) { return !!x; }) });
    });
};
