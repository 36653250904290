import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
var _excluded = ["children"];
import { Keys } from '../../../../../groups/type';
export var useKey = function useKey(_ref) {
    var children = _ref.children, props = _objectWithoutPropertiesLoose(_ref, _excluded);
    var key = '';
    var uppercase = false;
    if (typeof children !== 'string') {
        key = '?'; // common
        if (children === Keys.shift) {
            key = 'Shift';
        }
        if (children === Keys.tab) {
            key = 'Tab';
        } // mac
        if (children === Keys.option) {
            key = 'Option';
        }
        if (children === Keys.control) {
            key = 'Control';
        } // windows
        if (children === Keys.alt) {
            key = 'Alt';
        }
        if (children === Keys.ctrl) {
            key = 'Ctrl';
        }
        if (children === Keys.wheel) {
            key = 'Wheel';
        }
        if (children === Keys.f1) {
            key = 'F1';
        }
        if (children === Keys.f2) {
            key = 'F2';
        }
        if (children === Keys.f3) {
            key = 'F3';
        }
        if (children === Keys.f4) {
            key = 'F4';
        }
        if (children === Keys.f5) {
            key = 'F5';
        }
        if (children === Keys.f6) {
            key = 'F6';
        }
        if (children === Keys.f7) {
            key = 'F7';
        }
        if (children === Keys.f8) {
            key = 'F8';
        }
        if (children === Keys.f9) {
            key = 'F9';
        }
        if (children === Keys.f10) {
            key = 'F10';
        }
        if (children === Keys.f11) {
            key = 'F11';
        }
        if (children === Keys.f12) {
            key = 'F12';
        }
        if (children === Keys.lmb) {
            key = 'LMB';
        }
        if (children === Keys.rmb) {
            key = 'RMB';
        }
        if (children === Keys.mmb) {
            key = 'MMB';
        }
        if (children === Keys.enter) {
            key = 'Enter';
        }
        if (children === Keys.left_arrow) {
            key = '←';
        }
        if (children === Keys.right_arrow) {
            key = '→';
        }
        if (children === Keys.down_arrow) {
            key = '↓';
        }
        if (children === Keys.up_arrow) {
            key = '↑';
        }
        if (children === Keys.space) {
            key = 'Space';
        }
    }
    else {
        uppercase = children.length === 1;
        key = children;
    }
    return { rootProps: Object.assign({}, props, {
            uppercase: uppercase
        }), key: key };
};
