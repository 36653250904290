import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useRootRef } from '../../../../hooks';
import { sendMessage } from '../../../../services/message';
import { ContactFormState } from '../../../../states';
export var useContacts = function useContacts(ref, props) {
    var _ContactFormState$use = ContactFormState.useContainer(), message = _ContactFormState$use.message, contact = _ContactFormState$use.contact, agreed = _ContactFormState$use.agreed, set = _ContactFormState$use.set, reset = _ContactFormState$use.reset;
    var rootRef = useRootRef(ref);
    var formSubmission = useMutation(sendMessage);
    var onSubmitButtonClick = useCallback(function () { formSubmission.mutate({ text: message, contact: contact }); }, [formSubmission, message, contact]);
    var isLoading = formSubmission.isLoading, isSuccess = formSubmission.isSuccess, isError = formSubmission.isError;
    useEffect(function () { if (isSuccess) {
        reset();
    } }, [isSuccess]);
    return { rootProps: Object.assign({}, props, {
            ref: rootRef
        }), messageFieldProps: { disabled: isLoading, value: message, onChange: function onChange(event) { return set({ message: event.target.value }); } }, contactFieldProps: { disabled: isLoading, value: contact, onChange: function onChange(event) { return set({ contact: event.target.value }); } }, consentButtonProps: { disabled: isLoading, checked: agreed, onChange: function onChange(event) { return set({ agreed: event.target.checked }); } }, submitButtonProps: { disabled: isLoading || !message || !agreed, onClick: onSubmitButtonClick }, success: isSuccess, failure: isError, loading: isLoading, showForm: !isSuccess };
};
