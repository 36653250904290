export var usePageLayout = function usePageLayout(_ref) {
    var _frontMatter$title, _frontMatter$keywords, _frontMatter$descript, _frontMatter$displayP, _frontMatter$images, _coverImage;
    var pageContext = _ref.pageContext, _ref$title = _ref.title, titleProp = _ref$title === void 0 ? '' : _ref$title, _ref$keywords = _ref.keywords, keywordsProp = _ref$keywords === void 0 ? [] : _ref$keywords, _ref$description = _ref.description, descriptionProp = _ref$description === void 0 ? '' : _ref$description, displayPageTitleProp = _ref.displayPageTitle, children = _ref.children;
    var frontMatter = pageContext === null || pageContext === void 0 ? void 0 : pageContext.frontmatter;
    var actualTitle = (_frontMatter$title = frontMatter === null || frontMatter === void 0 ? void 0 : frontMatter.title) !== null && _frontMatter$title !== void 0 ? _frontMatter$title : titleProp;
    var actualKeywords = (_frontMatter$keywords = frontMatter === null || frontMatter === void 0 ? void 0 : frontMatter.keywords) !== null && _frontMatter$keywords !== void 0 ? _frontMatter$keywords : keywordsProp;
    var actualDescription = (_frontMatter$descript = frontMatter === null || frontMatter === void 0 ? void 0 : frontMatter.description) !== null && _frontMatter$descript !== void 0 ? _frontMatter$descript : descriptionProp;
    var displayPageTitle = (_frontMatter$displayP = frontMatter === null || frontMatter === void 0 ? void 0 : frontMatter.displayPageTitle) !== null && _frontMatter$displayP !== void 0 ? _frontMatter$displayP : true;
    var images = (_frontMatter$images = frontMatter === null || frontMatter === void 0 ? void 0 : frontMatter.images) !== null && _frontMatter$images !== void 0 ? _frontMatter$images : [];
    var reallyDisplayTitle = displayPageTitleProp === undefined ? displayPageTitle !== false : displayPageTitleProp;
    var showTitle = actualTitle && reallyDisplayTitle;
    var coverImage = '';
    if (images) {
        var coverImageData = images[0];
        if (coverImageData !== null && coverImageData !== void 0 && coverImageData.image) {
            if (typeof (coverImageData === null || coverImageData === void 0 ? void 0 : coverImageData.image) === 'string') {
                coverImage = coverImageData === null || coverImageData === void 0 ? void 0 : coverImageData.image;
            }
            else if (coverImageData !== null && coverImageData !== void 0 && coverImageData.image.childImageSharp) { // @ts-ignore
                coverImage = coverImageData === null || coverImageData === void 0 ? void 0 : coverImageData.image.childImageSharp.fluid.src;
            }
        }
    }
    return { seoProps: { title: actualTitle, keywords: actualKeywords, description: actualDescription, image: (_coverImage = coverImage) !== null && _coverImage !== void 0 ? _coverImage : '/assets/icon.jpg' }, showTitle: showTitle, title: actualTitle, children: children };
};
